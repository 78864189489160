import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
export const accessibilityOverviewQuery = graphql`
  query AccessibilityOverviewQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  accessibilityOverviewQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`content goes here`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      